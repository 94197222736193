<template>
  <transition appear enter-active-class="animated fadeIn">
    <div>
      <div class="level-item">
        <h1 class="title is-1 page-title">Travel Insurance for Dubai</h1>
      </div>
      <div class="box b-shdw-3">
		<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
		<div class="content">
                    <p class="paragraph-text">
                        Dubai is the largest city in the United Arab Emirates (UAE) and is a tourist hotspot in this part of the world. The city is home to the tallest building in the world and is a mega-city of commerce, business and exciting nightlife.  
                    </p>
                    <p class="paragraph-text">
                        Though it has been developed immensely over recent years, Dubai still holds on to its traditional values and culture. It is a rare destination where you can explore both modern architecture and the oldest of Arabic traditions at the same time. 
                    </p>
                    <p class="paragraph-text">
                        One of its major draws is the weather, as it is typically warm all year round and offers plenty of things to do both indoors and outdoors. One day you can be going on a trip filled with culture, luxury, and modern architecture, and the next you could be riding a camel, sandboarding, or dune bashing in the desert. There are plenty of activities to keep you entertained, making Dubai a popular choice for a holiday.  
                    </p>
                    <p class="paragraph-text">
                        No matter your plans for your trip, it is important to get travel insurance for Dubai, to help protect you against most unforeseen eventualities.
                    </p>
                    <p class="blue-text">Do I need travel insurance for Dubai?</p>
                    <p class="paragraph-text">
                        Travel insurance is not a legal requirement; however, you shouldn’t travel without it. There is no access to free medical treatment in Dubai, so if you fell ill or got injured, you would need travel insurance or the means to pay for any medical expenses yourself. 
                    </p>
                    <p class="paragraph-text">
                        Generally, the city is deemed to be very safe, but like all tourist hotspots, there can be some pickpocketing, and therefore, it is a good idea to have cover in place to protect your personal belongings. 
                    </p>
                    <p class="paragraph-text">
                        Travel insurance will not only cover your belongings and medical expenses, but will also cover you for cancellation, curtailment, travel delays and personal liability, plus much more. It is important to arrange travel insurance, to give yourself peace of mind before and during your trip.  
                    </p>
                    <p class="blue-text">Do I need a visa for Dubai?</p>
                    <p class="paragraph-text">
                        If you are a British Citizen, you will need to obtain a visa. It’s easy to arrange and provides you with up to 40 days, with the option to extend (for a fee) at the immigration office. Visit the FCDO website for more information on <a href="https://www.gov.uk/foreign-travel-advice/united-arab-emirates/entry-requirements" target="_blank" class="text-link">Dubai’s entry requirements</a>.
                    </p>

                    <p class="blue-text">What should I think about when I compare Dubai Travel insurance?</p>
                    <li class="blue-text">Does your travel insurance cover Dubai?</li>
                    <p class="paragraph-text">
                        Here at Covered2go, for travel insurance to Dubai, you will need to purchase a ‘<strong>Worldwide excluding USA, Canada and Caribbean</strong>’ policy. Although, if you plan on travelling to more destinations, for example, to the USA, Canada or the Caribbean, then you would need a worldwide policy which includes all of these destinations.
                    </p>
                    <p class="paragraph-text">
                        Travel insurance is there just in case any unforeseen circumstances happen. So, to ensure any mishaps are covered, such as, delayed flights, or a trip cancellation due to illness, or medical assistance, that is what travel insurance is there for, to cover those unexpected expenses or to help recoup costs.  
                    </p>
                    <li class="blue-text">Are all your activities covered?</li>
                    <p class="paragraph-text">
                        There are many <a href="sports-activities-cover" target="_blank" class="text-link">sports and activities</a> to plan in Dubai. Some of them include sandboarding, dune bashing, quad biking, skydiving among others. Some of these activities would not be covered as standard and would need extra cover. It is important to cover any higher risk activities on your travel insurance, which you plan to take part in on your travels. 
                    </p>

                    <li class="blue-text">How much is the trip worth?</li>
                    <p class="paragraph-text">
                        How much money would you lose if you had to cancel? It’s important to factor in all of the costs associated with your holiday, including flights, accommodation, and pre-booked excursions. Your cancellation cover should at least meet the cost of all of those, so that if you needed to cancel and make a claim, you can recoup as much of the holiday costs as possible. 
                    </p>                        
                    <p class="paragraph-text">
                        Here at Covered2go we have cancellation cover available up to £10,000 per person, on our Platinum Policies. If you require cancellation cover beyond this amount, we would be happy to discuss the option of increasing cancellation cover if needed. 
                    </p>
                    <li class="blue-text">What is the purpose of your travel?</li>
                    <p class="paragraph-text">
                        If the purpose of travelling to Dubai is for business, you might want a travel insurance policy which is tailored to this type of trip, as there are benefits of having business cover, which are not covered on a standard travel insurance policy. Find out more about <router-link :to="{name:'businessTravelInsurance'}">business travel insurance</router-link>.
                    </p>

                    <p class="blue-text">Any tips for travelling to Dubai?</p>
                    <p class="paragraph-text">
                        Dubai is a stunning destination, and it offers not only beautiful sunsets, beaches but also breathtaking high rises, amazing restaurant experiences and plenty of adventure sports. It is a paradise for one who has multiple interests and wants a wholesome travel experience. 
                    </p>
                    <li class="blue-text">Explore the culture:</li>
                    <p class="paragraph-text">
                        Dubai is very welcoming to tourists, but it might be wise to learn about the etiquette and laws before you go. <a href="https://www.smartdubai.ae/" target="_blank" class="text-link">Click here</a> for more information about Dubai’s etiquette and culture.  
                    </p>
                    <p class="paragraph-text">
                        You can discover Dubai’s cultural gems on a walking tour, wandering through the winding alleyways of Al Fahidi Historical Neighbourhood. Read more: <a href="https://www.visitdubai.com/en/articles/dubai-cultural-walking-tour" target="_blank" class="text-link">Heritage Walk in Old Dubai</a>. 
                    </p>
                    <li class="blue-text">Tipping:</li>
                    <p class="paragraph-text">
                        Tipping is common and very welcome but isn’t mandatory. Food and drink outlets, usually in hotels can add service charge to your bill, which is usually around 10%. 
                    </p>
                    <li class="blue-text">Currency:</li>
                    <p class="paragraph-text">
                        The currency of Dubai is the UAE dirham (Dh) and notes come in denominations of 5,10,15,20,100,500 and 1000 dirham. Cash machines are available, and you can withdraw funds easily. 
                    </p>
                    <li class="blue-text">Time difference:</li>
                    <p class="paragraph-text">
                        Plan your travel as per the time difference between your country and Dubai for a smoother arrival. Dubai is 4 hours ahead of standard British time (GMT), and the average flight time to Dubai from London is 6 hours and 45 minutes. 
                    </p>
                    <p class="paragraph-text">
                        Here at Covered2go we can provide you with a quick travel insurance quote for your trip to Dubai. We aim to make our policies clear, so you know exactly what is covered and what isn’t. 
                    </p>
                    <p class="paragraph-text">
                        <a href="get-quote" target="_blank" class="text-link">Get a quote online today!</a> Or get in touch with a member of our friendly team if you have any questions.
                    </p>
</div>
				
				<InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
				<info-boxes/>
				<!-- <trustpilot-banner/> -->



			</div>
    </div>
  </transition>
</template>
<script>
import InfoBoxes from "../components/common/InfoBoxes";
//import TrustpilotBanner from "@/components/common/trustpilot/TrustpilotBanner";
import InfoButtonOptions from "@/components/common/InfoButtonOptions";
		
export default {
  name: 'UKTravelInsurance',
  mixins: [],
  components: {
			InfoBoxes,
			//TrustpilotBanner,
			InfoButtonOptions
	},
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
</style>

<style lang="scss" scoped>
    .main-page-title {color: $c2g_orange; font-size:130%; text-decoration:none; text-align:left; padding-bottom:15px; font-weight: bold;}
    .paragraph-text {color:black; font-size:100%; padding-bottom:15px;}
    .main-list-class{padding-left:25px; padding-bottom:15px;}
    .list-item{list-style-type: disc; color:black;}
    .blue-text {margin-top: 10px; text-align:left; color: $c2g_blue; font-weight: bold; font-size:115%; padding-bottom:15px;}
    .bold-text{font-weight:bold;}
    .text-link{color:$c2g_orange; text-decoration: underline;}
</style>